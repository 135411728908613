import React, { useState } from "react"
import axios from "axios"
import { Link } from "gatsby"
import styled from "styled-components"

const MyForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/7f785111-e1be-48a1-b6a0-33ae15e2c055",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, "Сообщение отправлено!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <FormA onSubmit={handleOnSubmit}>
      <input type="hidden" name="form-name" value="contact-form" />
      <p className="title">Укажите контактные данные</p>
      <p className="subtitle">
        Наш специалист перезвонит вам в ближайшее время
      </p>
      <input type="text" name="name" id="name" placeholder="Ваше имя" />
      <input
        required
        type="tel"
        name="tel"
        id="tel"
        placeholder="+7 (___) ___-__-__"
      />
      <button type="submit" disabled={serverState.submitting}>
        Отправить
      </button>
      <small>
        Нажимая на кнопку, вы принимаете{" "}
        <Link to="/regulation/">
          <a>Положение</a>
        </Link>{" "}
        и &nbsp;
        <Link to="/consent/">
          <a>Согласие</a>
        </Link>{" "}
        на обработку персональных данных.
      </small>

      {serverState.status && (
        <p className={!serverState.status.ok ? "errorMsg" : ""}>
          {serverState.status.msg}
        </p>
      )}
    </FormA>
  )
}

export default MyForm

const FormA = styled.form`
  display: grid;
  grid-gap: 1em;
  min-width: 300px;
  .title {
    font-size: 21.5px;
    font-weight: bold;
    text-align: center;
  }
  .subtitle {
    font-size: 18px;
    text-align: center;
  }
  > p:last-child {
    font-size: 18px;
    text-align: center;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }
  input {
    background-color: rgba(240, 240, 240, 1);
    display: grid;
    grid-gap: 1em;
    width: 100%;
    height: 2.5em;
    color: rgba(89, 89, 89, 1);
    font-size: 14px;
    border-color: transparent;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border: 1px solid #cccccc;
    padding: 0.5em;
  }
  button {
    background-color: var(--clr-accent);
    border: none;
    height: 2.5em;
    width: 160px;
    margin: 0 auto;
    color: white;
    font-size: 1.4rem;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
    :hover {
      border: solid 2px var(--clr-accent);
    }
    :active {
      transform: scale(0.95);
    }
  }
  small {
    font-size: 12px;
    text-align: center;
    a {
      color: var(--clr-accent);
      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`

// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

// import React from "react"

// export default class MyForm extends React.Component {
//   constructor(props) {
//     super(props)
//     this.submitForm = this.submitForm.bind(this)
//     this.state = {
//       status: "",
//     }
//   }

//   render() {
//     const { status } = this.state
//     return (
//       <form
//         onSubmit={this.submitForm}
//         action="https://formspree.io/mgezlozk"
//         method="POST"
//       >
//         <label>Email:</label>
//         <input type="email" name="email" />
//         <label>Message:</label>
//         <input type="text" name="message" />
//         {status === "SUCCESS" ? <p>Thanks!</p> : <button>Submit</button>}
//         {status === "ERROR" && <p>Ooops! There was an error.</p>}
//       </form>
//     )
//   }

//   submitForm(ev) {
//     ev.preventDefault()
//     const form = ev.target
//     const data = new FormData(form)
//     const xhr = new XMLHttpRequest()
//     xhr.open(form.method, form.action)
//     xhr.setRequestHeader("Accept", "application/json")
//     xhr.onreadystatechange = () => {
//       if (xhr.readyState !== XMLHttpRequest.DONE) return
//       if (xhr.status === 200) {
//         form.reset()
//         this.setState({ status: "SUCCESS" })
//       } else {
//         this.setState({ status: "ERROR" })
//       }
//     }
//     xhr.send(data)
//   }
// }

// import React from "react"
// import styled from "styled-components"

// const Form = () => {
//   return (
//     <FormA method="post" netlify-honeypot="bot-field" data-netlify="true">
//       <input type="hidden" name="bot-field" />

//       <p className="title">Укажите контактные данные</p>
//       <p className="subtitle">
//         Наш специалист перезвонит вам в ближайшее время
//       </p>
//       <input type="text" name="name" id="name" placeholder="Ваше имя" />
//       <input
//         required
//         type="tel"
//         name="tel"
//         id="tel"
//         placeholder="+7 (___) ___-__-__"
//       />
//       <button type="submit">Отправить</button>
//       <small>
//         Нажимая на кнопку, вы принимаете <a>Положение</a> и <a>Согласие</a> на
//         обработку персональных данных.
//       </small>
//     </FormA>
//   )
// }
// export default Form

// const FormA = styled.form`
//   display: grid;
//   grid-gap: 1em;
//   min-width: 300px;
//   .title {
//     font-size: 21.5px;
//     font-weight: bold;
//   }
//   .subtitle {
//     font-size: 18px;
//     text-align: center;
//   }
//   input {
//     background-color: rgba(240, 240, 240, 1);
//     display: grid;
//     grid-gap: 1em;
//     width: 100%;
//     height: 2.5em;
//     color: rgba(89, 89, 89, 1);
//     font-size: 14px;
//     border-color: transparent;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//     border: 1px solid #cccccc;
//     padding: 0.5em;
//   }
//   button {
//     background-color: var(--clr-accent);
//     border: none;
//     height: 2.5em;
//     width: 160px;
//     margin: 0 auto;
//     color: white;
//     font-size: 1.4rem;
//     :hover {
//       border: solid 2px var(--clr-accent);
//     }
//   }
//   small {
//     font-size: 12px;
//     text-align: center;
//     a {
//       color: var(--clr-accent);
//       :hover {
//         cursor: pointer;
//         text-decoration: underline;
//       }
//     }
//   }
// `

// // import React from "react"
// // import styled from "styled-components"
// // const Form = () => {
// //   return (
// //     <FormA
// //       method="post"
// //       name="contact-form"
// //       data-netlify="true"
// //       data-netlify-honeypot="bot-field"
// //     >
// //       <input type="hidden" name="form-name" value="contact-form" />
// //       <p className="title">Укажите контактные данные</p>
// //       <p className="subtitle">
// //         Наш специалист перезвонит вам в ближайшее время
// //       </p>
// //       <input type="text" name="name" id="name" placeholder="Ваше имя" />
// //       <input
// //         required
// //         type="tel"
// //         name="tel"
// //         id="tel"
// //         placeholder="+7 (___) ___-__-__"
// //       />
// //       <button type="submit">Отправить</button>
// //       <small>
// //         Нажимая на кнопку, вы принимаете <a>Положение</a> и <a>Согласие</a> на
// //         обработку персональных данных.
// //       </small>
// //     </FormA>
// //   )
// // }
// // export default Form

// // const FormA = styled.form`
// //   display: grid;
// //   grid-gap: 1em;
// //   min-width: 300px;
// //   .title {
// //     font-size: 21.5px;
// //     font-weight: bold;
// //   }
// //   .subtitle {
// //     font-size: 18px;
// //     text-align: center;
// //   }
// //   input {
// //     background-color: rgba(240, 240, 240, 1);
// //     display: grid;
// //     grid-gap: 1em;
// //     width: 100%;
// //     height: 2.5em;
// //     color: rgba(89, 89, 89, 1);
// //     font-size: 14px;
// //     border-color: transparent;
// //     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
// //     border: 1px solid #cccccc;
// //     padding: 0.5em;
// //   }
// //   button {
// //     background-color: var(--clr-accent);
// //     border: none;
// //     height: 2.5em;
// //     width: 160px;
// //     margin: 0 auto;
// //     color: white;
// //     font-size: 1.4rem;
// //     :hover {
// //       border: solid 2px var(--clr-accent);
// //     }
// //   }
// //   small {
// //     font-size: 12px;
// //     text-align: center;
// //     a {
// //       color: var(--clr-accent);
// //       :hover {
// //         cursor: pointer;
// //         text-decoration: underline;
// //       }
// //     }
// //   }
// // `
