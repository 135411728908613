import React from "react"
import styled from "styled-components"
import YandexMap from "../components/YandexMap"
import FtrMail from "../images/ftr-icon-mail.png"
import FtrMap from "../images/ftr-icon-map.png"
import Button from "../components/button"

//------------------------------------------------------------------------------------------------------------------------------
// FTR - Footer - подвал
// 1.25rem  - 20px
// 1.375rem - 22px
// 1.125rem - 18px
// 5.25rem  - 84px
// 2.25rem  - 36px
// 2.5rem   - 40px
// 1.438rem - 23px
// 1.563rem - 25px
// 5.375rem - 85px
//------------------------------------------------------------------------------------------------------------------------------

const Section = styled.section`
  margin: 0;
  padding: 0;
  background-color: #efefef;
  z-index: -20;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 436px 764px 1fr;
  font-family: var(--fw-primary);
  padding: 0px;
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 100% 1fr;
  }
`

const Background = styled.div`
  display: grid;
  grid-template-columns: 1fr 436px 764px 1fr;
  padding: 0;
  margin: -391px 0 0 0;
  height: 391px;
  z-index: -10;
  @media (max-width: 1250px) {
    display: none;
  }
  div:nth-child(1) {
    width: 100%;
    grid-column: 3;
    background-color: red;
  }
  div:nth-child(2) {
    width: 100%;
    grid-column: 4;
    background-color: red;
  }
`

const Contacts = styled.div`
  grid-column: 2;
  padding: 30px 0 0 0;

  @media (max-width: 1250px) {
    grid-row: 1;
    width: 432px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 640px) {
    width: 300px;
  }
`

const Contacts__Phone = styled.div`
  padding: 0;
  margin: 0 20px 0 0;
  border-bottom: 4px solid red;
  b {
    font-size: 2.25rem;
    letter-spacing: 3px;
    font-weight: 700;
    @media (max-width: 640px) {
      font-size: 1.7rem;
    }
  }
  p {
    letter-spacing: 2px;
    font-size: 2.5rem;
    line-height: 1;
    height: 88px;
    font-weight: 400;
    @media (max-width: 640px) {
      font-size: 1.9rem;
      height: auto;
    }
  }
`

const Contacts__MailStreet = styled.div`
  padding: 0;
  margin: 51px 0 0 0;
  ul {
    list-style-type: none;
    li {
      display: flex;
      p {
        margin: 0 0 0 16px;
        font-size: 1.438rem;
        height: 37px;
        @media (max-width: 640px) {
          font-size: 1.2rem;
        }
      }
      i {
        background-image: url(${FtrMail});
        background-repeat: no-repeat;
        background-size: 37px;
        height: 37px;
        width: 37px;
        @media (max-width: 640px) {
          background-size: 30px;
          height: 30px;
          width: 30px;
        }
      }
    }
    li:nth-child(2) {
      margin: 60px 0 0 0;
      p {
        height: 60px;
        line-height: 1.3;
      }
      i {
        background-image: url(${FtrMap});
        background-repeat: no-repeat;
        background-size: 37px;
        @media (max-width: 640px) {
          background-size: 30px;
          height: 30px;
          width: 30px;
        }
      }
    }
  }
`

const Contacts__Consultation = styled.div`
  padding: 0;
  margin: 64px 0 0 0;
`

const Map = styled.div`
  grid-column: 3;
  display: flex;
  flex-direction: column;
  padding: 0;
  @media (max-width: 1250px) {
    grid-column: 2;
    grid-row: 2;
    margin: 30px 0 0 0;
  }
`

const Map__Title = styled.div`
  padding: 0;
  margin: 18px 0 0 0;
  text-align: right;
  display: block;
  @media (max-width: 1500px) {
    text-align: right;
    margin-right: 1.3em;
  }
  @media (max-width: 1250px) {
    text-align: left;
    margin: 18px 0 0 24px;
    width: 540px;
  }
  @media (max-width: 640px) {
    height: 62px;
    width: 292px;
  }
  p {
    color: red;
    font-size: 5.375rem;
    text-transform: uppercase;
    white-space: pre-wrap;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    display: block;
    @media (max-width: 640px) {
      font-size: 2.7rem;
    }
  }
`

const Map__Position = styled.div`
  display: block;
  margin: -22px 0 0 0;
  padding: 24px;
  /* z-index: 10; */
  @media (max-width: 1250px) {
    background-color: red;
  }
`

const Map__Info = styled.div`
  display: block;
  padding: 0 24px 24px 24px;
  z-index: 1;
  @media (max-width: 1250px) {
    background-color: red;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.3;
    ${"" /* color: var( --clr-dark); */}
    color: var( --clr-light);
    @media (max-width: 640px) {
      font-size: 0.9rem;
    }
  }
`

const Footer = () => {
  return (
    <>
      <Section>
        <Wrapper>
          <Contacts>
            <Contacts__Phone>
              <p>
                <a href="teL:+7(4012)697004">
                  <b>8(4012) 697-004</b>
                </a>
                <br />
                г. Калининград
              </p>
            </Contacts__Phone>
            <Contacts__MailStreet>
              <ul>
                <li>
                  <i></i>
                  <a href="mailto:sales@energoalyans.com">
                    <p>sales@energoalyans.com</p>
                  </a>
                </li>
                <li>
                  <i></i>
                  <p>
                    ул. Полоцкая 45, 4 этаж <br /> Торговый центр
                    &#34;Полоцкий&#34;
                  </p>
                </li>
              </ul>
            </Contacts__MailStreet>
            <Contacts__Consultation>
              <Button />
            </Contacts__Consultation>
          </Contacts>
          <Map>
            <Map__Title>
              <p>
                <b>гк альянс</b>
              </p>
            </Map__Title>
            <Map__Position>
              <YandexMap />
            </Map__Position>
            <Map__Info>
              <p>
                ИНН 3906980837 ОГРН 1163926053312 Ф-Л «ЕВРОПЕЙСКИЙ» ПАО «БАНК
                <br /> «САНКТ-ПЕТЕРБУРГ» БИК 042748877 КОР.СЧЕТ №
                30101810927480000877 <br />
                РАСЧЕТНЫЙ СЧЕТ № 40702810675000074902
              </p>
            </Map__Info>
          </Map>
        </Wrapper>
        <Background>
          <div></div>
          <div></div>
        </Background>
      </Section>
    </>
  )
}

export default Footer
