import React from "react"
import styled from "styled-components"
import Modal from "react-modal"
import MyForm from "./MyForm"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
  },
}

const Button = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <Modal
        backdropColor={"green"}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <MyForm
          style={{
            overflow: "auto",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "-2px",
            right: "3px",
            backgroundColor: "transparent",
            border: "none",
            color: "var(--clr-accent)",
            fontSize: "1.4rem",
            outline: "none",
          }}
          onClick={closeModal}
        >
          &#x2718;
        </button>
      </Modal>
      <ButtonCall onClick={openModal}>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="47"
            viewBox="0 0 512 512"
            fill="white"
            preserveAspectRatio="xMinYMin meet"
          >
            <path d="M502 407l-57-38-50-33c-10-7-23-5-30 5l-31 40c-7 8-19 11-29 6-21-12-46-23-101-79-56-55-67-80-79-101-5-10-2-22 6-29l40-31c10-7 12-20 5-30l-32-49-39-58C98 0 85-3 75 3L30 30C18 37 9 49 5 62c-12 45-15 144 143 302s257 155 302 143c13-4 25-13 32-25l27-45c6-10 3-23-7-30zM291 79c83 1 150 68 150 151a9 9 0 0018 0c0-93-75-168-168-168a9 9 0 000 17z" />
            <path d="M291 132c54 0 97 44 97 98a9 9 0 0018 0c0-64-51-115-115-115a9 9 0 000 17z" />
            <path d="M291 185c25 0 44 20 44 45a9 9 0 0018 0c0-35-28-62-62-62a9 9 0 000 17z" />
          </svg>
        </span>
        <p>Заказать консультацию</p>
      </ButtonCall>
    </>
  )
}

export default Button

const ButtonCall = styled.button`
  align-self: center;
  transition: all 0.2s ease-in-out;
  font-family: var(--ff-primary);
  justify-self: flex-start;
  :active {
    transform: scale(0.95);
  }
  display: flex;
  background-color: #fff;
  border: solid 2px var(--clr-accent);
  cursor: pointer;
  margin: -1px;
  span {
    background-color: var(--clr-accent);
    padding: 0.5em;
    margin: -1px;
    max-height: 70px;
    max-width: 70px;
}
    svg {
      min-height: 50px;
      height: 100%;
      min-width: 50px;
    }
  }
  p {
    font-family: var(--ff-primary);
    margin: auto 0.3em;
    color: var(--clr-accent);
    line-height: 22px;
    font-size: 1.5625rem;
    /* padding: 0 0.2em; */
    font-weight: 500;
  }
  @media (min-width: 1250px) {
    white-space: nowrap;
  }
`
