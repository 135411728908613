import React from 'react'
import { YMaps, Map, Placemark } from "react-yandex-maps"
import styled from "styled-components"


const YandexStyle = styled.div`
  height: 250px;
  width: 846px;
  display: block;
  margin: 0;
  padding: 0;
  @media (max-width: 1500px) {
    width: 100%;   
  }
`

const YandexMap = () => {
  return (
    <YandexStyle>
      <YMaps>
        <Map
          width="100%"
          height="100%"
          defaultState={{
            center: [54.703286, 20.498639],
            zoom: 16,
            controls: ['zoomControl', 'fullscreenControl'],
          }}
          modules={['control.ZoomControl', 'control.FullscreenControl']}
        >
          <Placemark defaultGeometry={[54.703286, 20.498639]} />
        </Map>
      </YMaps>
    </YandexStyle>
  )
};

export default YandexMap