import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const MainHeader = styled.header`
  display: grid;
  padding: 1em;

  div {
    order: 2;
    display: flex;
    align-items: center;
  }
  h1 {
    font-weight: var(--fw-bold);
    color: var(--clr-accent);
    font-size: 3rem;
  }
  h2 {
    line-height: 0.95;
    font-size: 2.2rem;
    a {
      font-weight: 700;
      font-size: 1.8rem;
      letter-spacing: 3px;
    }
    span {
      font-weight: var(--fw-reg);
      letter-spacing: 1px;
      font-size: 2rem;
      letter-spacing: 2px;
    }
  }
  div {
    padding: 0.5em 0;
    border-bottom: solid 4px var(--clr-accent);
  }
  @media (min-width: 500px) {
    h1 {
      font-size: 3.5rem;
    }
    h2 {
      line-height: 0.95;
      font-size: 2.2rem;
      a {
        font-weight: 700;
        font-size: 2.25rem;
        letter-spacing: 3px;
      }
      span {
        font-weight: var(--fw-reg);
        letter-spacing: 1px;
        font-size: 2.5rem;
        letter-spacing: 2px;
      }
    }
  }
  @media (min-width: 1000px) {
    padding-top: 0;
    h1 {
      order: 2;
      font-size: var(--fs-h1);
    }
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    grid-column: 2/3;
    position: relative;
    bottom: -0.95em;
  }
`

const Header = ({ siteTitle }) => (
  <MainHeader>
    <div>
      <h2>
        <a href="tel:+7(4012)697004">8(4012) 697-004</a>
        <br />
        <span>г. Калининград</span>
      </h2>
    </div>
    <h1>{siteTitle}</h1>
  </MainHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
